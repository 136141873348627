import axios from '@/common/js/request'

export function safetyInspectorDropDown(data){
    const safetyInspectorDropDown = axios({
        url: '/dataStatistics/safetyInspectorDropDown',
        method: 'get',
        params:data
    })
    return safetyInspectorDropDown
}
export function cycleSecurityCheck(data){
    const cycleSecurityCheck = axios({
        url: '/dataStatistics/cycleSecurityCheck',
        method: 'get',
        params:data
    })
    return cycleSecurityCheck
}
export function userTaskList(data){
    const userTaskList = axios({
        url: '/dataStatistics/userTaskList',
        method: 'get',
        params:data
    })
    return userTaskList
}
export function visitWorstTask(data){
    const visitWorstTask = axios({
        url: '/dataStatistics/visitWorstTask',
        method: 'get',
        params:data
    })
    return visitWorstTask
}
export function addrDropDown(data){
    const addrDropDown = axios({
        url: '/dataStatistics/addrDropDown',
        method: 'get',
        params:data
    })
    return addrDropDown
}
export function cycleSecurityCheckExcel(params){
    const cycleSecurityCheckExcel = axios({
        url: '/dataStatistics/cycleSecurityCheckExcel',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return cycleSecurityCheckExcel
}
export function userTaskListExcel(params){
    const userTaskListExcel = axios({
        url: '/dataStatistics/userTaskListExcel',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return userTaskListExcel
}
export function visitWorstTaskExcel(params){
    const visitWorstTaskExcel = axios({
        url: '/dataStatistics/visitWorstTaskExcel',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return visitWorstTaskExcel
}